import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Link, graphql } from 'gatsby'
import { Moment } from 'moment';

import Layout, { extractData, THEMES } from '../components/layout'
import { Page, Routing, SSRAware, MovieVM, posterWidthAndHeight } from '../components/utils'
import { Movie, Days, Cinema, CinemaId } from '../model/model'

import MovieList from '../components/movie-list';
import Section from '../components/section';
import { renderTruncatedDescription } from '../components/movie-summary';
import { TaggedPoster } from '../components/tagged-poster';
import createStore from '../model/create-store'
import { Provider } from 'react-redux';
import ConnectedUpdater from '../components/updater';
import RImage from '../components/image';
import { Tracking } from '../model/utils';

const shortDescription = renderTruncatedDescription(25, 0, false)
const longDescription = renderTruncatedDescription(55, 0, false)

interface PageProps {
  cinemas: Cinema[]
  comingSoon: Movie[]
  nowShowing: Movie[]
  events: Movie[]
  now: Moment
  mainCinemaId: CinemaId
}

const Card = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media (min-width: 700px) {
    padding: 5px 10px;
  }
`
const Poster = styled(RImage)`
  display: flex;
  object-fit: cover;
  ${posterWidthAndHeight(120)}
  @media (min-width: 700px) {
    ${posterWidthAndHeight(130)}
  }
`
const Title = styled.h3`
  display: flex;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
  @media (min-width: 700px) {
    line-height: 2rem;
  }
`
const SubTitle = styled.span`
  display: flex;
  color: ${props => props.theme.colors.attenuate};
  font-size: 0.8rem;
  line-height: 1rem;
  padding-top: 0.1rem;
`
const Text = styled.div`
  display: flex;
  overflow: hidden;
  height: 90px;
  font-size: 0.8rem;
  line-height: 1.1rem;
  margin: 0;
  flex-grow: 1;
  @media (min-width: 700px) {
    font-size: 1rem;
    line-height: 1.3rem;
  }
`

const MobileText = styled(Text)`
  display: flex;
  @media (min-width: 700px) {
    display: none;
  }
`

const DesktopText = styled(Text)`
  display: none;
  @media (min-width: 700px) {
    display: flex;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  width: 100%;
`

interface MovieCardProps {
  movie: Movie
  mainCinemaId: CinemaId
}
const MovieCard: React.SFC<MovieCardProps & SSRAware> = ({isSSR, movie, mainCinemaId}) => (
  <Card>
    <TaggedPoster tags={MovieVM.getTagsForEvent(movie)} fontSize={0.55}>
      <Poster mode={isSSR ? 'stub' : 'lazy'} image={movie.poster} alt={`Poster film ${movie.title}`}
        sizes={[{viewport: 700, size: 120}]}
        maxSize={150} />
    </TaggedPoster>
    <Content>
      <Title>{movie.title}</Title>
      <SubTitle>{ MovieVM.getReleaseMessage(movie, mainCinemaId) }</SubTitle>
      <MobileText>{shortDescription(movie)}</MobileText>
      <DesktopText>{longDescription(movie)}</DesktopText>
    </Content>
  </Card>
)

const ProssimamentePage: React.SFC<PageProps & SSRAware> = ({isSSR, cinemas, mainCinemaId, now, comingSoon, nowShowing, events}) => (
  <Layout showBackButton={false} cinemas={cinemas} title="Prossimamente">
    <Page>
      { comingSoon.length > 0 &&
        <Section title="Prossimamente">
          { comingSoon.map (m =>
            <Link key={m.id} to={Routing.toMoviePage(m)}
              onClick={() => {Tracking.trackNavigation('coming-soon/main', m.title)}}>
              <MovieCard isSSR={isSSR} movie={m} mainCinemaId={mainCinemaId}/>
            </Link>
          )}
        </Section>
      }
      { nowShowing.length > 0 &&
        <Section title="In Programmazione" linkTo="/">
          <MovieList look='slider1' lazy={true}
            items={MovieVM.computeMovieListForNowShowing(cinemas, nowShowing, Days.fromMoment(now))}
            onCardClick={(item, idx) => {Tracking.trackNavigation('coming-soon/now-showing-list', item.movie.title, idx.toString())}}/>
        </Section>
      }
      { events.length > 0 &&
        <Section title="Eventi" linkTo="/eventi">
          <MovieList look='slider2' lazy={true}
            items={MovieVM.computeMovieListForEvents(cinemas, events, Days.fromMoment(now))}
            onCardClick={(item, idx) => {Tracking.trackNavigation('coming-soon/events-list', item.movie.title, idx.toString())}}/>
        </Section>
      }
    </Page>
  </Layout>
)

const WrappedPage: React.SFC<any> = ( query ) => {
  const { mainCinemaId, cinemas } = extractData(query)
  const store = createStore("home", cinemas, mainCinemaId, false)
  const { isSSR, nowShowing, comingSoon, events, now } = store.getState()
  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <Provider store={store}>
        <>
          <ConnectedUpdater/>
          <ProssimamentePage cinemas={cinemas} mainCinemaId={mainCinemaId}
            isSSR={isSSR} events={events} now={now}
            comingSoon={comingSoon} nowShowing={nowShowing}/>
        </>
      </Provider>
    </ThemeProvider>
  )
}

export default WrappedPage

export const query = graphql`
query ProssimamentePageQuery {
  ...alldata
}
`
